.skeletonContainer {
  display: flex;
  flex-direction: column;
}

.navRow {
  width: auto;
  height: var(--spacing-mega);
  padding: var(--spacing-xxsmall) 0;
  margin: var(--spacing-xxsmall) var(--spacing-xsmall);
  margin-inline-start: var(--spacing-small);
}

.blockRow {
  width: auto;
  height: calc(var(--spacing-mega) * 6);
  margin: var(--spacing-xxsmall) var(--spacing-xsmall);
  margin-inline-start: var(--spacing-small);
}
