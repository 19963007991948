@use "src/styles/utility";
@use "src/styles/breakpoints";

.itemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  background-color: var(--color-background-elevated);
}

.centerVertically {
  @include utility.center-vertically;
}

.leftCTA {
  display: flex;
  margin-inline-start: var(--spacing-medium);
  align-items: center;
  width: fit-content;
  @include breakpoints.tablet {
    margin-inline-start: var(--spacing-mega);
  }
  > span > svg {
    fill: var(--themed-bg);
    // padding-left: 20px;
    >g{
      fill: var(--themed-bg);
    }
  }
  >span:nth-child(2) {
    margin-right: 0px;
    // padding-right: 15px;
    // width: 10px;
  }
}

.rightCTA {
  display: flex;
  margin-inline-end: var(--spacing-medium);
  align-items: center;

  > button {
    margin-inline-start: var(--spacing-micro);
  }

  @include breakpoints.tablet {
    margin-inline-end: var(--spacing-mega);

    > button {
      margin-inline-start: var(--spacing-xsmall);
    }
  }
}
