.trigger {
  all: unset;
  display: inline-block;
}

.content {
  z-index: var(--z-index-sticky);
  font-size: var(--font-size-normal);
  border-radius: var(--border-radius-default);
  padding-block-start: var(--spacing-xxsmall);
  padding-block-end: var(--spacing-xxsmall);
  padding-inline-start: var(--spacing-xxsmall);
  padding-inline-end: var(--spacing-xxsmall);
  color: var(--color-text-inverse);
  background-color: var(--color-background-inverse);
  text-align: center;
  max-width: calc(10 * var(--spacing-mega));
  & > span {
    fill: var(--color-background-inverse);
  }
}

.noInverse {
  background-color: var(--color-background-default);
  & > span {
    fill: var(--color-background-default);
  }
}
.noCenter {
  text-align: initial;
}
.secondary {
  background-color: var(--color-secondary-medium);
  & > span {
    fill: var(--color-secondary-medium);
  }
}
.success {
  background-color: var(--color-success-medium);
  & > span {
    fill: var(--color-success-medium);
  }
}
.warning {
  background-color: var(--color-warning-medium);
  & > span {
    fill: var(--color-warning-medium);
  }
}
.error {
  background-color: var(--color-error-medium);
  & > span {
    fill: var(--color-error-medium);
  }
}
