@use "src/styles/theme";

$content-animation-easing: cubic-bezier(0.16, 1, 0.3, 1);
$overlay-background-light: hsla(0, 0%, 100%, 0.8);
$overlay-background-dark: rgba(0, 0, 0, 0.8);
$overlay-background-sepia: rgba(239, 226, 205, 0.7);
$shadow: 0 16px 70px rgb(0 0 0 / 20%); // using custom shadow for now until we fix our token and design in dark theme;
$width: 95vw;
$max-width: calc(20 * var(--spacing-mega));
$max-height: 85vh;
$min-height: calc(9 * var(--spacing-mega));

@keyframes contentShow {
  0% {
    opacity: 0;
    transform: var(--content-translate-position) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: var(--content-translate-position) scale(1);
  }
}

@keyframes contentHide {
  0% {
    opacity: 1;
    transform: var(--content-translate-position) scale(1);
  }
  100% {
    opacity: 0;
    transform: var(--content-translate-position) scale(0.96);
  }
}

.content {
  --content-translate-position: translate(-50%, -50%);
  [dir="rtl"] & {
    --content-translate-position: translate(50%, -50%);
  }

  background-color: var(--color-background-default);
  border-radius: var(--border-radius-rounded);
  box-shadow: $shadow;
  position: fixed;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: var(--content-translate-position);
  width: $width;
  max-width: $max-width;
  max-height: $max-height;
  min-height: $min-height;
  display: flex;
  flex-direction: column;
  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: contentShow var(--transition-fast) $content-animation-easing;
    }
    &[data-state="closed"] {
      animation: contentHide var(--transition-fast) $content-animation-easing;
    }
  }
  &:focus {
    outline: none;
  }
  z-index: var(--z-index-modal);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlayHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.overlay {
  @include theme.light {
    background-color: $overlay-background-light;
  }
  @include theme.dark {
    background-color: $overlay-background-dark;
  }
  @include theme.sepia {
    background-color: $overlay-background-sepia;
  }
  backdrop-filter: blur(6px);
  position: fixed;
  inset: 0;

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: overlayShow var(--transition-fast) ease;
    }
    &[data-state="closed"] {
      animation: overlayHide var(--transition-fast) ease;
    }
  }
  z-index: var(--z-index-overlay);
}
