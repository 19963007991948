@use "src/styles/theme";
$baseSize: var(--spacing-mega);
$animationDuration: 8s;

.baseSize {
  height: $baseSize;
  width: $baseSize;
}

.skeleton {
  position: relative;
  display: block;
  border-radius: var(--border-radius-default);
}

.active::before {
  border-radius: var(--border-radius-default);

  @include theme.light {
    background-color: var(--color-secondary-faded);
  }
  @include theme.dark {
    background-color: var(--color-background-alternative-faded);
  }

  animation: 1.5s ease-in-out 0.5s infinite normal none running loading;

  content: " ";
  position: absolute;

  inset-block: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
}

.rounded::before {
  border-radius: var(--border-radius-circle);
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.content {
  opacity: 0;
}
