@use "src/styles/shades";

@mixin mode {
  --color-primary-faded: #fff;
  --color-primary-faint: #fff;
  --color-primary-medium: #000;
  --color-primary-deep: #fff;

  --color-secondary-faded: #eaeaea;
  --color-secondary-faint: #999;
  --color-secondary-medium: #999; // temporary placeholder for the beta.
  --color-secondary-deep: #333;

  --color-success-faded: #ebf9fa;
  --color-success-faint: #c2edef;
  --color-success-medium: #2ca4ab;
  --color-success-deep: #258c91;

  --color-error-faded: #f7d4d6;
  --color-error-faint: #ff1a1a;
  --color-error-medium: #ee0102;
  --color-error-deep: #c50000;

  --color-warning-faded: #fef3dc;
  --color-warning-faint: #ffdfaa;
  --color-warning-medium: #ffb300;
  --color-warning-deep: #ab570a;

  --color-text-default: #272727;
  --color-text-faded: #666;
  --color-text-inverse: #fff;
  --color-text-link: #2ca4ab;
  --color-text-warning: #ab570a;
  --color-text-error: #c50000;

  --color-background-default: #fff;
  --color-background-elevated: #fff;

  --color-background-alternative-faded: var(--shade-0);
  --color-background-alternative-faint: var(--shade-1);
  --color-background-alternative-medium: var(--shade-2);
  --color-background-alternative-deep: var(--shade-6);

  --color-background-inverse: #000;
  --color-background-backdrop: rgba(0, 0, 0, 0.6);
  --color-background-lighten: hsla(0, 0%, 100%, 0.8);
  // lighten the `background-color` by using it in `background-image`. check Button's ghost variant for example

  --color-borders-hairline: rgb(235, 238, 240);

  --color-highlight: #79ffe1;

  --shadow-small: 0px 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-normal: 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-large: 0 5px 10px rgba(0, 0, 0, 0.12);
  --shadow-xlarge: 0 8px 30px rgba(0, 0, 0, 0.12);
  --shadow-jumbo: 0 30px 60px rgba(0, 0, 0, 0.12);
  --shadow-hover: 0 30px 60px rgba(0, 0, 0, 0.12);
  --shadow-sticky: 0 12px 10px -10px rgba(0, 0, 0, 0.12);
  font-palette: --Light;

  // scrollbar styles
  --scrollbar-background: var(--shade-2);
  --scrollbar-thumb: var(--shade-4);
  --scrollbar-thumb-hover: var(--shade-6);
  --scrollbar-thumb-active: var(--shade-7);
}