.searchInputContainer {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.searchInput {
  width: 100%;
  border: 0;
  color: var(--color-text-default);
  background: transparent;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: none;
  }
}

.clear {
  background: none;
  border: none;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-text-default);
  font-size: var(--font-size-xsmall);
}

.separator {
  max-height: var(--spacing-mega);
  margin-inline: calc(0.5 * var(--spacing-xxsmall));
}
