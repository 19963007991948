.progressRoot {
  position: relative;
  overflow: hidden;
  background-color: var(--color-background-alternative-faint);
  border-radius: var(--border-radius-pill);
  width: 100%;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.progressSmall {
  height: var(--spacing-medium);
}

.progressMedium {
  height: var(--spacing-large);
}

.progressLarge {
  height: var(--spacing-mega);
}

.progressIndicator {
  background-color: var(--color-success-medium);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
