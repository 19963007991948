.triggerContainer {
  border: 1px solid var(--color-text-default);
}

.iconContainer {
  display: flex;
}
.suffixIconContainer {
  display: flex;
  position: relative;
  inset-block-end: 1px;
  transform: rotate(180deg);
}
