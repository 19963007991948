@use "sass:math";

// animations
@mixin transform {
  transform: translate3d(0, 0, 0);
}

@keyframes slideOutDown {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

.animate {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}
.enter {
  animation-name: slideInUp;
}
.exit {
  animation-name: slideOutDown;
}

// toast hover transition
.toast {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: var(--spacing-xxsmall);
  margin-inline-end: var(--spacing-xxsmall);
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-xlarge);
  transition: transform 0.4s, opacity 0.4s ease;
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;

  @for $i from 2 through 5 {
    &:nth-last-child(#{$i}) {
      transform: translate3d(0, #{$i * -7}px, 0)
        scale(#{math.div(100 - $i, 100)});
    }
  }

  &:nth-last-child(n + 4) {
    visibility: hidden;
  }
}

.toastContainer:hover > .toast {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);

  @for $i from 2 through 3 {
    &:nth-last-child(#{$i}) {
      transform: translate3d(0, #{($i - 1) * -80}px, 0) scale(1);
    }
  }
}

.errorBody {
  background-color: var(--color-error-medium);
  color: var(--shade-0);
}

.warningBody {
  background-color: var(--color-warning-medium);
  color: var(--shade-0);
}

.successBody {
  background-color: var(--color-success-medium);
  color: var(--shade-0);
}

// toast style
.toastContainer {
  box-shadow: none;
  --toastify-toast-width: 480px;
  min-height: calc(10 * var(--spacing-large));
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  [dir="rtl"] & {
    flex-direction: row-reverse;
  }
}

.actionsContainer {
  display: flex;
}

.actionsContainer > .action + .action {
  margin-inline-start: var(--spacing-xxsmall);
}
