.tooltipContainer {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-small);
  color: var(--color-text-inverse);
  background-color: var(--color-background-inverse);
  max-width: 100%;
  width: calc(var(--spacing-mega) * 10);
  z-index: var(--z-index-onboarding-step);
}

.title {
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-bold);
}

.description {
  margin-top: var(--spacing-xsmall);
  font-size: var(--font-size-small);
}

.icon {
  width: 24px;
  height: 24px;
}

.actionContainer {
  margin-top: var(--spacing-large);
  display: flex;
  align-items: center;
  gap: var(--spacing-xxsmall);
}
