$separator-size: 1px;

.base {
  &[data-orientation="horizontal"] {
    height: $separator-size;
    width: 100%;
  }

  &[data-orientation="vertical"] {
    height: 100%;
    width: $separator-size;
  }
}

.semiBold {
  background-color: var(--color-borders-hairline);
}

.bold {
  background-color: var(--color-primary-medium);
  &[data-orientation="horizontal"] {
    height: calc(1.28 * $separator-size);
  }

  &[data-orientation="vertical"] {
    width: calc(1.28 * $separator-size);
  }
}
