.container {
  display: flex;
  align-items: center;
  padding-block: var(--spacing-small);
  text-decoration: none;
}
.poweredBy {
  font-size: var(--font-size-xsmall);
  line-height: normal;
  color: var(--color-text-faded);
  display: flex;
  margin-inline-end: var(--spacing-xxsmall);
}

.tarteelTextWrapper {
  display: flex;
  align-items: center;
  & > svg > path {
    fill: var(--color-text-default);
  }
}
